import useMutationGraphQL from '~/core/middleware/use-mutation-graphQL'

export function useSubmitCommon(query: string) {
  const { trigger, isLoading, data, error } = useMutationGraphQL({
    query
  })

  return {
    trigger,
    isLoading,
    data,
    error
  }
}
