'use client'

import * as SwitchWrapper from '@radix-ui/react-switch'
import { cva } from 'class-variance-authority'
import { forwardRef, useEffect, useState } from 'react'
import IconWrapper from '~/core/ui/IconWrapper'
import { cn, getDisabledSelectedCondition } from '~/core/ui/utils'

const switchVariants = cva(
  'relative flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
  {
    variants: {
      variant: {
        default: 'hover:bg-gray-300 dark:hover:bg-gray-500',
        check: 'hover:bg-primary-600 dark:hover:bg-primary-300'
      },
      size: {
        md: 'h-6 w-[42px] min-w-[42px] min-h-[24px]',
        sm: 'h-5 w-[34px] min-w-[34px] min-h-[20px]'
      },
      disabled: {
        default:
          'focus:ring-1 focus:ring-primary-300 dark:focus:ring-primary-700',
        disabled: 'pointer-events-none'
      },
      checkedCondition: {
        noDisabledSelected: 'bg-gray-200 dark:bg-gray-600',
        noDisabledWithSelected: 'bg-primary-400 dark:bg-primary-400',
        disabledNoSelect: 'bg-gray-50 dark:bg-gray-800',
        disabledWithSelect: 'bg-primary-200 dark:bg-primary-800'
      },
      orderLast: {
        default: '',
        'order-last': 'order-last'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'sm',
      disabled: 'default',
      checkedCondition: 'noDisabledSelected',
      orderLast: 'default'
    }
  }
)

const toggleRootVariants = cva('relative', {
  variants: {
    variant: {
      onlyText: 'flex items-center',
      textWithDescription: 'inline-flex'
    },
    size: {
      md: 'space-x-3',
      sm: 'space-x-2'
    },
    disabled: {
      default: '',
      disabled: 'pointer-events-none'
    },
    reverse: {
      default: '',
      reverse: 'space-x-reverse'
    }
  },
  defaultVariants: {
    variant: 'onlyText',
    size: 'sm',
    disabled: 'default',
    reverse: 'default'
  }
})

const switchThumbVariants = cva(
  'pointer-events-none flex items-center justify-center transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out translate-x-0',
  {
    variants: {
      variant: {
        'check-size-sm': 'data-[state=checked]:translate-x-[14px]',
        'check-size-md': 'data-[state=checked]:translate-x-[18px]'
      },
      size: {
        md: 'h-5 w-5',
        sm: 'h-4 w-4'
      }
    },
    defaultVariants: {
      variant: 'check-size-sm',
      size: 'sm'
    }
  }
)

interface ToggleProps {
  icon?: boolean
  size?: 'sm' | 'md'
  toggle?: 'trailing' | 'leading'
  text?: string
  supportingText?: string
  description?: string

  name?: string
  isChecked?: boolean
  onCheckedChange?: (checked: boolean) => void
  isDisabled?: boolean
  required?: boolean
  className?: string
  isDebounce?: boolean
  classNameConfig?: {
    [key: string]: string
  }
}

const Toggle = forwardRef<
  React.ElementRef<typeof SwitchWrapper.Root>,
  ToggleProps
>((props, ref) => {
  const {
    icon = false,
    size = 'sm',
    toggle = 'trailing',
    text = '',
    supportingText = '',
    description = '',

    name = 'view-switch',
    isChecked = false,
    onCheckedChange = undefined,
    isDisabled = false,
    required = false,
    className = '',
    isDebounce = false,
    classNameConfig
  } = props
  const [checkedState, setCheckedChange] = useState(isChecked)
  useEffect(() => {
    setCheckedChange(isChecked)
  }, [isChecked])

  const fontSizeLabel = size === 'md' ? 'text-base' : 'text-sm'
  const fontSizeIcon = size === 'md' ? 14 : 12

  return (
    <div
      className={cn(
        toggleRootVariants({
          size,
          variant: text && !description ? 'onlyText' : 'textWithDescription',
          disabled: isDisabled ? 'disabled' : 'default',
          reverse: toggle === 'leading' ? 'reverse' : 'default'
        })
      )}>
      <SwitchWrapper.Root
        ref={ref}
        name={name}
        disabled={isDisabled}
        required={required}
        className={cn(
          switchVariants({
            size,
            variant: checkedState ? 'check' : 'default',
            disabled: isDisabled ? 'disabled' : 'default',
            checkedCondition: getDisabledSelectedCondition({
              isDisabled,
              isSelected: checkedState
            }),
            orderLast: toggle === 'leading' ? 'order-last' : 'default',
            className
          })
        )}
        checked={checkedState}
        onCheckedChange={(checked) => {
          if (isDebounce === false) {
            setCheckedChange(checked)
          }

          if (onCheckedChange) {
            onCheckedChange(checked)
          }
        }}>
        <SwitchWrapper.Thumb
          className={cn(
            switchThumbVariants({
              size,
              variant:
                checkedState && size === 'sm'
                  ? 'check-size-sm'
                  : 'check-size-md'
            })
          )}>
          {icon ? (
            <>
              {isChecked === false ? (
                <IconWrapper name="X" size={fontSizeIcon} />
              ) : (
                <IconWrapper
                  name="Check"
                  size={fontSizeIcon}
                  className="text-primary-400"
                />
              )}
            </>
          ) : null}
        </SwitchWrapper.Thumb>
      </SwitchWrapper.Root>

      {text && !description ? (
        <div
          className={cn(
            'flex items-center space-x-1',
            classNameConfig?.contentContainer
          )}>
          <span className={`${fontSizeLabel} text-gray-600 dark:text-gray-300`}>
            {text}
          </span>
          {supportingText ? (
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {supportingText}
            </p>
          ) : null}
        </div>
      ) : null}

      {text && description ? (
        <div className="space-y-1">
          {text ? (
            <div
              className={cn(
                'flex items-center space-x-1',
                classNameConfig?.contentContainer
              )}>
              <span
                className={`${fontSizeLabel} font-medium text-gray-900 dark:text-gray-200`}>
                {text}
              </span>

              {supportingText ? (
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {supportingText}
                </span>
              ) : null}
            </div>
          ) : null}
          {description ? (
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {description}
            </p>
          ) : null}
        </div>
      ) : null}
    </div>
  )
})

Toggle.displayName = 'Toggle'

export { Toggle }
export type { ToggleProps }
