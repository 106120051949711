import { signOut as signOutNexAuth, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import configuration from '~/configuration'
import useMiddlewareRequest from '~/core/middleware/use-middleware-request'

export function useSubmitSignOut() {
  const router = useRouter()
  const { data: session } = useSession()
  const { trigger, isMutating, data, error } = useMiddlewareRequest({
    endpoint: configuration.api.signOut,
    method: 'POST'
  })

  const handleSignOut = async () => {
    await trigger({})

    if (session) {
      await signOutNexAuth({ redirect: false })
    }
    router.push(configuration.path.login)
  }

  return {
    handleSignOut,
    isLoading: isMutating,
    data,
    error
  }
}
