import Gleap from 'gleap'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import configuration from '~/configuration'
import { Avatar } from '~/core/ui/Avatar'
import { DropdownMenu } from '~/core/ui/DropdownMenu'
import { useSubmitSignOut } from '~/lib/features/sign-out/hooks/use-submit-sign-out'
import useBoundStore from '~/lib/store'

interface HeaderProps {
  isLogin?: boolean
}
const Header = (props: HeaderProps) => {
  const { isLogin = false } = props
  const { user } = useBoundStore()
  const { t } = useTranslation()
  const { handleSignOut } = useSubmitSignOut()
  const router = useRouter()

  if (isLogin) {
    const getInfoName = () => {
      if (!user.fullName) return user.email
      return `${user.fullName}`
    }

    const signOut = async () => {
      await handleSignOut()
      if (Gleap && Gleap.getInstance().initialized === true) {
        Gleap.clearIdentity()
      }
    }

    return (
      <div className="h-[56px] border-b border-b-gray-100 px-8 py-3">
        <div className="flex justify-between">
          <Link href={configuration.path.default}>
            <Image
              width={133}
              height={32}
              src="/img/logo/logo-primary-full.png"
              alt="logo"
              priority
            />
          </Link>

          <DropdownMenu
            align="end"
            sideOffset={8}
            menuClassName="w-[220px]"
            trigger={
              <div className="cursor-pointer">
                <Avatar
                  color={user.defaultColour}
                  alt={getInfoName()}
                  size="md"
                  src={user.avatarVariants?.thumb?.url}
                />
              </div>
            }
            menu={[
              {
                label: 'My Dashboard',
                icon: 'User',
                onClick: () => router.push(configuration.path.resumes.dashboard)
              },
              {
                label: 'Account settings',
                icon: 'Settings',
                onClick: () => router.push(configuration.path.myAccount)
              },
              'separate-line',
              {
                label: 'Log out',
                icon: 'LogOut',
                onClick: () => signOut()
              }
            ]}
          />
        </div>
      </div>
    )
  }

  return <>Header</>
}

export default Header
