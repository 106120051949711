import { FCC } from '~/core/@types/global'
import { cn } from '~/core/ui/utils'
import Header from '../Header'

interface LayoutGridProps {
  background?: string
}

const LayoutGrid: FCC<LayoutGridProps> = (props) => {
  const { children, background } = props

  return (
    <div className={cn('min-h-screen', background)}>
      <Header isLogin />
      {children}
    </div>
  )
}

export default LayoutGrid
